.header{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    background: #1A2934;
    height: 7.5rem;
}
.shopezzLogo{
    margin-left: 15vw;
}
.header-text{
    cursor: pointer;
    font-size: calc(0.7em + 0.5vw);
    margin-left: 15vw;
    color: #ffff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
}
#main-img.jumbotron{
    background-image:
    linear-gradient(259.19deg, #233949 18.05%, rgba(74, 91, 103, 0.755263) 46.52%, rgba(196, 196, 196, 0) 96.65%),
    url('../../assets/images/main_img.png');
    width: 100%;
    height: 700px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
   
}
#main-img h2{
    position: absolute;
    top: 16vw;
    left: 58vw;
    transform: matrix(-1, 0, 0, 1, 0, 0);
   
}
.img-title{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 129.19%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    
}
.shopez-content{
    float: right;
}
.steps-text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 131.19%;
    letter-spacing: 2px;
    color: #1A2934;
    text-align: center;
}
.stepshr{
   margin-top: 4vw;
}
.stepss-text{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #1A2934;
    text-align: center;
    
}
.steps-disc{
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 155.5%;
    text-align: center;
    color: #636363;
}
.stepdiv{
    align-items: center;
}
.logoc{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1700px) {
    .stepshr {
        margin-top: 4.5vw;

        hr {
            width: 10vw !important;
        }
    }
}

@media screen and (max-width: 1550px) {
    .steps-disc {
        font-size: 14px;
    }

    .stepss-text {
        font-size: 22px;
    }

    .steps-text {
        font-size: 38px;
    }

    .img-title {
        font-size: 48px;
    }

    .stepshr {
        margin-top: 6vw;
    }
}

@media screen and (max-width: 1400px) {
    .steps-text {
        font-size: 32px;
    }

    .img-title {
        font-size: 40px;
    }

    #main-img h2 {
        left: 55vw;
    }

    .stepshr {
        hr {
            width: 11vw !important;
        }
    }
}
@media screen and (max-width: 1100px) {
    .steps-disc {
        font-size: 12px;
    }

    .stepss-text {
        font-size: 16px;
    }

    .steps-text {
        font-size: 28px;
    }

    .img-title {
        font-size: 38px;
    }
    .stepshr {
        margin-top: 8vw;

        hr {
            width: 10vw !important;
        }
    }
    #main-img h2 {
        left: 50vw;
    }

    .header {
        height: 6.5rem;

        .shopezzLogo {
            width: 160px;
        }
    }

    #main-img.jumbotron {
        height: 600px;
    }
}



