.adminOrderSearch {
    padding-left: 2vw;
    border: 1px solid #233949;
}

.Order-date-div {
    width: 280px; //200px;
    height: 165px; //200px;
    border-radius: 4px; //10px;
    border: 1px solid #1051C2;
    background: #F0F4FB; //#004E7A;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left: 1rem;
}

.Order-complete-div{
    width: 280px;//180px;
    height: 165px;//150px;
    margin-left: 1rem;
    border: 1px solid #107A2E;//C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;//10px;
    background: #E8F6EC;
    .order-num {
        color: #107A2E;
    }
}
.Order-cancle-div{
    width: 280px;//180px;
    height: 165px;//150px;
    margin-left: 1rem;
    border: 1px solid #A61111;//C0C0C0;
    box-sizing: border-box;
    border-radius: 4px;//10px;
    background: #FBF6F6;
    .order-num {
        color: #A61111;
    }
}

.order-num{
    color: #1051C2;//#FFFFFF;
    font-family: "Inter", Arial, sans-serif;//"Segoe UI"
    font-size: 72px;//52px;
    font-style: normal;
    font-weight: 500;//600;
    line-height: normal; //78px;
    padding-left: 16px;
    // padding-top: 5px;
}

.order-day{
    color: #303030;//#FFFFFF;
    font-family: "Inter", Arial, sans-serif;//"Segoe UI"
    font-size: 20px;//14px;
    font-style: normal;
    font-weight: 700;//600;
    line-height: normal; //18px;
    padding-left: 16px;
    padding-top: 12px;
}

.order-time{
    color: #303030;//#FFFFFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;//normal;
    line-height: normal; //26px;
    width: 100%;//100px;
    font-family: "Inter", Arial, sans-serif;//"Segoe UI"
    padding-left: 16px;
    padding-top: 18px;
    padding-bottom: 13px;
    padding-right: 21px;
}

.complete-icon {
    width: 45px;
    height: 45px;
}
.right-part_order_top{
    display: inline-block;
    position: absolute;
    right: 30px;
    font-family: "Inter", Arial, sans-serif;

    .title_orders{
        color: #101010;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        padding-bottom: 17px;
    }
    .desc_orders{
        width: 100%;
        text-align: right;

        .order{
            color: black;//#505050;
            text-align: right;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal; 
            display: initial;
            padding-right: 20px;
        }
        div.order::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px; 
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: #FFC000;
            border-radius: 22px;
            margin-right: 4px;
            vertical-align: text-top;
        }
        div.order.ready_order::before {
            background-color: #92d050;
        }
        div.order.returned_order::before {
            background-color: #c00000;
        }
        div.order.shiped_order::before{
            background-color: #00b050;
        }
        div.order.received_order::before {
            background-color: #fa7f04;
        }
        div.order.cancelled_order::before {
            background-color: #FF0000;
        }
    }
}

.order-complete-num {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 66px;
    color: #808080;
}

.order-complete-text {
    width: 100px;
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
}

.filter-text {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

.OrderSection {
    cursor: pointer;
    margin-top: 0px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0vw 1vw;
}

.filter-date-text {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #808080;
}

.status-box {
    width: 100%;
    height: 4vw;
    background-color: #ECECEC;
}

.status-box-text {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #808080;
}

.status-text {
    font-family: "Segoe UI", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-text-ml {
    margin-left: 8rem;
}

.status-text-ml-pr {
    margin-left: 9rem;
}

.status-text-ml-od {
    margin-left: 6rem;
}

.status-text-ml-od-c {
    margin-left: 10rem;
}

.orderlisting-color {
    text-align: left;
    height: 2vw;
    width: 0.5rem;
    padding: 0 !important;
    margin: 0 !important;
}
.refundAdmin{
    text-align: left;
    height: 2vw;
    width: 0.5rem;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #00b050;
}

.orderNumber {
    .MuiFilledInput-input {
        padding: 12px;
    }
}

.customerNumber {
    .MuiFilledInput-input {
        padding: 12px;
    }
}

.orderFilterBtn {
    float: unset !important;
    background: #233949 !important;
    color: #fff;
    border: 1px solid #fff !important;
}

.OrderReceivedColor {
    color: #fa7f04;
}

.OrderinProcessColor {
    color: #FFC000;
}

.OrderReadyColor {
    color: #92d050;
}

.OrderShippedColor {
    color: #00b050;
}

.OrderCancelledColor {
    color: #FF0000;
}

.OrderCancelledColor.PartiallyCancellation {
    color: #92D14F;
}

.OrderReturnedColor {
    color: #c00000;
}

.OrderReturnedColor.PartiallyReturned {
    color: #92D14F;
}

.OrderReceivedBar {
    background-color: #fa7f04;
}

.OrderinProcessBar {
    background-color: #FFC000;
}

.OrderReadyBar {
    background-color: #92d050;
}

.OrderShippedBar {
    background-color: #00b050;
}

.OrderCancelledBar {
    background-color: #FF0000;
}

.OrderCancelledBar.PartiallyCancellation {
    background-color: #92D14F;
}

.OrderReturnedBar {
    background-color: #c00000;
}

.OrderReturnedBar.PartiallyReturned {
    background-color: #92D14F;
}

.filterCloseIcon {
    text-align: right;
    background: #000;
    color: #fff;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: none;
    box-shadow: none;
    padding: 0px 5px;
}

@media screen and (max-width: 1550px) {

    .order-complete-text,
    .status-box-text,
    .status-text {
        font-size: 12px !important;
    }

    .status-box {
        height: 5vw;
    }
    .order-day{
        font-size: 20px;
    }
    .order-time{
        font-size: 16px;
    }
    .Order-cancle-div{
        width: 280px;
        height: 165px;
    }
    .order-complete-num {
        font-size: 36px;
    }
    .order-num {
        font-size: 72px;
    }
    .Order-complete-div {
        width: 280px;
        height: 165px;
    }

    .complete-icon {
        width: 40px;
        height: 40px;
    }
    .Order-date-div {
        width: 280px;
        height: 165px;
    }
}

@media screen and (max-width: 1400px) {
    .status-box {
        height: 5.5vw;
    }
}

@media screen and (max-width: 1100px) {
    .status-box {
        height: 6.5vw;
    }
}
@media (max-width: 1700px) {
    .right-part_order_top{
        position: unset;
        padding-top: 30px;
        padding-left: 20px;
    }
}

@media (max-width: 790px) {
    .right-part_order_top{
        width: 100%;
    }
    .right-part_order_top .desc_orders .order{
        width: 100%;
        padding-top: 15px;
        display: block;
        text-align: left;
    }
}